<template>
  <div justify="center">
    <a
      id="couponPriceColor"
      href="javascript:void(0);"
      @click="openForm"
    >-{{ formatData(coupon) }}</a>

    <v-dialog
      v-model="dialog"
      max-width="600px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span
            class="text-h5"
            style="color: red"
          >クーポン-編集</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row align="center" class="px-2 ma-0 my-2">
              <v-col cols="12" md="3">
                <span style="color:#636363">割引値</span>
              </v-col>
              <v-col
                cols="5"
              >
                <NumberInput
                  v-model="$v.discountValue.$model"
                  :outlined="true"
                  :error-messages="couponErrors"
                  prefix="¥"
                ></NumberInput>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions align="center" class="d-flex justify-center">
          <v-btn
            color="success"
            @click="closeForm()"
          >
            YES
          </v-btn>
          <v-btn
            class="mx-2"
            color="error"
            @click="closeForm()"
          >
            NO
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {
  mdiEye,
  mdiClose,
} from '@mdi/js'
import {
  required,
  numeric,
} from 'vuelidate/lib/validators'
import NumberInput from '@/components/Input/NumberInput.vue'

export default {
  components: {
    NumberInput,
  },
  props: ['coupon'],
  data: () => ({
    icons: {
      mdiEye,
      mdiClose,
    },
    discountValue: 0,
    dialog: false,
  }),
  validations: {
    discountValue: {
      required,
      numeric,
    },

  },
  computed: {
    formatData() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return data.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },

    couponErrors() {
      const errors = []
      if (!this.$v.discountValue.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.discountValue.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.discountValue.numeric && errors.push('必須数字')

      return errors
    },
  },
  watch: {
  },
  created() {
  },
  methods: {
    openForm() {
      this.discountValue = this.coupon
      this.dialog = true
    },
    closeForm() {
      this.dialog = false
      console.log('coupon discount:', this.discountValue)
      this.$emit('couponChanged', this.discountValue)
    },
  },
}
</script>

<style scoped>
#couponPriceColor {
  color: red !important;
}
</style>
