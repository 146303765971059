var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"justify":"center"}},[_c('v-btn',{attrs:{"color":"secondary","outlined":"","elevation":"0"},on:{"click":function($event){$event.stopPropagation();return _vm.openForm.apply(null, arguments)}}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDotsHorizontal)+" ")])],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"1100px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('overlays',{attrs:{"is-absolute":true,"is-show":_vm.absoluteOverlayStatus}}),_c('div',{attrs:{"id":"products-list"}},[_c('v-card',[_c('v-card-title',[_vm._v("商品検索")]),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('v-text-field',{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Search","outlined":"","hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer')],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"show-select":"","headers":_vm.headers,"items":_vm.tableList,"item-key":"product_code","search":_vm.search},scopedSlots:_vm._u([{key:"item.product_name",fn:function(ref){
var item = ref.item;
return [(item.product_name)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({staticClass:"class-with-title",staticStyle:{"margin":"0","width":"14rem","overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"}},'p',attrs,false),on),[_vm._v(" "+_vm._s(item.product_name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.product_name))])]):_vm._e()]}},{key:"item.product_tip",fn:function(ref){
var item = ref.item;
return [_c('label',[_vm._v(_vm._s(item.product_tip.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')))])]}},{key:"item.product_type",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',[_vm._v(_vm._s(_vm.statuss[item.product_type - 1] ? _vm.statuss[item.product_type - 1].text : ''))])])]}}],null,true),model:{value:(_vm.selectedList),callback:function ($$v) {_vm.selectedList=$$v},expression:"selectedList"}}),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":_vm.goBack}},[_c('span',[_vm._v("OK")])]),_c('v-btn',{attrs:{"color":"secondary","outlined":""},on:{"click":function($event){_vm.dialog = false}}},[_c('span',[_vm._v("キャンセル")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }