<template>
  <div>
    <v-card>
      <v-card-title>注文実績(編集)</v-card-title>
      <v-spacer></v-spacer>
      <template v-if="apierror.status == 'error'">
        <div
          v-for="msg of apierror.messages"
          :key="msg"
        >
          <v-row class="ml-6 mb-3 ma-3">
            <span style="color: red">* {{ msg }} </span>
          </v-row>
        </div>
      </template>
      <v-divider></v-divider>
      <v-row align="center" class="px-2 ma-0 py-0 pt-2">
        <v-col
          cols="12"
          md="2"
        >
          <label>会員ID<span style="color: red">*</span></label>
        </v-col>
        <v-col
          cols="12"
          md="5"
        >
          <v-text-field
            v-model="$v.OrderForm.mbr_id.$model"
            :error-messages="ordMbrIdErrors"
            readonly
            outlined
            dense
            placeholder="会員ID"
            :value="newCusId"
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" class="px-2 ma-0 py-0">
        <v-col
          cols="12"
          md="2"
        >
          <label>会員氏名<span style="color: red">*</span></label>
        </v-col>
        <v-col
          cols="12"
          md="5"
        >
          <v-text-field
            v-model="$v.OrderForm.mbr_name.$model"
            :error-messages="ordMbrNameErrors"
            readonly
            outlined
            dense
            placeholder="会員氏名"
            :value="newCusName"
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" class="px-2 ma-0 py-0 pb-2">
        <v-col
          cols="12"
          md="2"
          align-self="start"
        >
          <label>送付先<span style="color: red">*</span></label>
        </v-col>
        <v-col
          cols="12"
          md="5"
        >
          <v-text-field
            v-model="$v.OrderForm.addr_name.$model"
            :error-messages="ordAdrNameErrors"
            readonly
            outlined
            dense
            placeholder="送付先"
            :value="adrName"
            hide-details="auto"
          ></v-text-field>
          <p style="font-size:14px;margin-top:2px">
            {{ OrderForm.addr_addr1 }} {{ OrderForm.addr2 }}
          </p>
        </v-col>
        <v-col
          cols="12"
          md="1"
          align-self="start"
        >
          <Addresses
            :mbr-id="OrderForm.mbr_id"
            :addr-id-list="addrIdList"
            @add_adrlist="refreshAddress"
          ></Addresses>
        </v-col>
      </v-row>
      <v-row align="center" class="px-2 ma-0 py-0 pb-2">
        <v-col
          cols="12"
          md="2"
        >
          <label style="font-size:16px;color:#636363">注文日時</label><span style="color: red">*</span>
        </v-col>
        <v-col
          cols="12"
          md="3"
          class="py-0 my-0"
        >
          <span>{{ OrderForm.order_dt }}</span>
        </v-col>
      </v-row>
      <v-row align="center" class="px-2 ma-0 py-0 pb-2">
        <v-col
          cols="12"
          md="2"
        >
          <label style="font-size:16px;color:#636363">決済予定日</label><span style="color: red">*</span>
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="py-0 my-0"
        >
          <v-menu
            v-model="datePudMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="OrderForm.order_pud"
                placeholder="決済予定日"
                color="#fa7a00"
                dense
                hide-details="auto"
                outlined
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="OrderForm.order_pud"
              color="#fa7a00"
              @input="datePudMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col
          cols="12"
          md="2"
        >
          <label style="font-size:16px;color:#636363">発送予定日</label>
          <!-- <span style="color: red">*</span> -->
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="py-0 my-0"
        >
          <v-menu
            v-model="dateEadMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="OrderForm.order_ead"
                placeholder="発送予定日"
                color="#fa7a00"
                dense
                hide-details="auto"
                outlined
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="ordDay"
              color="#fa7a00"
              @input="dateEadMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-divider></v-divider>
      <v-row align="center" class="px-2 ma-0 py-1">
        <v-col
          cols="12"
          md="1"
        >
          <label>カート</label>
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          cols="12"
          md="1"
          class="mr-3 py-0"
        >
          <Product
            :list="cartList"
            @add_prolist="getaddProList"
          ></Product>
        </v-col>
      </v-row>
      <v-data-table
        ref="Contracts"
        class="class-on-data-table elevation-0"
        :headers="newHeaders"
        :items="cartList"
      >
        <!-- <template v-slot:[`item.product_img`]="{ item }">
          <div class="p-2">
            <v-img
              :src="item.product_img ? item.product_img : require('@/assets/images/noimage.jpg')"
              max-height="50"
              max-width="50"
              class="me-3"
            ></v-img>
          </div>
        </template> -->
        <template v-slot:[`item.product_name`]="{ item }">
          <v-tooltip v-if="item.product_name" bottom>
            <template v-slot:activator="{ on, attrs }">
              <p
                class="class-with-title"
                style="margin: 0; width:14rem; overflow: hidden; text-overflow:ellipsis;white-space: nowrap;"
                v-bind="attrs"
                v-on="on"
              >
                {{ item.product_name }}
              </p>
            </template>
            <span>{{ item.product_name }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.sum_min`]="{ item }">
          <v-icon
            class="mr-2 pr-0"
            color="primary"
            @click="decrement(item)"
          >
            {{ icons.mdiMinusBox }}
          </v-icon>
        </template>
        <template v-slot:[`item.order_qty`]="{ item }">
          <v-text-field
            v-model="item.order_qty"
            style="padding-right:0px"
            readonly
            reverse
            outlined
            dense
            hide-details
            class="text-right"
          ></v-text-field>
        </template>
        <template v-slot:[`item.sum_plus`]="{ item }">
          <v-icon
            class="mr-2"
            color="primary"
            @click="increment(item)"
          >
            {{ icons.mdiPlusBox }}
          </v-icon>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            class="mr-2"
            color="red"
            @click="remove(item)"
          >
            {{ icons.mdiMinusCircle }}
          </v-icon>
        </template>
        <template v-slot:[`item.order_tip`]="{ item }">
          <label>{{ item.order_tip.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') }}</label>
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          <label>{{ item.amount.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') }}</label>
        </template>
        <template v-slot:[`body.append`]>
          <tr>
            <td
              class="font-weight-bold"
              :colspan="7"
              style="background: #fafafa"
            >
              <label>小計</label>
            </td>
            <td
              class="font-weight-bold"
              style="text-align: right; background: #fafafa"
            >
              <label>{{ formatData(getSumPrice) }}</label>
            </td>
            <td
              style="background: #fafafa"
              :colspan="1"
            ></td>
          </tr>
          <tr>
            <td
              :colspan="7"
            >
              <label>送料</label>
            </td>
            <td
              style="text-align: right;"
            >
              <label>{{ formatData(shippingFee) }}</label>
            </td>
            <td
              :colspan="1"
            ></td>
          </tr>
          <tr>
            <td
              :colspan="7"
            >
              <label>クーポン</label>
            </td>
            <td
              style="text-align: right;"
            >
              <OrderEditCouponDialog
                :coupon="couponDiscount"
                @couponChanged="couponChanged"
              ></OrderEditCouponDialog>
            </td>
            <td
              :colspan="1"
            ></td>
          </tr>
          <tr>
            <td
              class="font-weight-bold"
              style="background: #fafafa"
              :colspan="6"
            >
              <label>合計価格(円)</label>
            </td>
            <td
              style="background: #fafafa"
              :colspan="1"
            ></td>
            <td
              class="font-weight-bold"
              style="text-align: right; background: #fafafa"
            >
              <label>{{ formatData(orderTotal) }}</label>
            </td>
            <td
              style="background: #fafafa"
              :colspan="1"
            ></td>
          </tr>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <v-row align="center" class="px-2 ma-0 py-1">
        <v-col
          cols="12"
          md="2"
        >
          <label>注文状態<span style="color: red">*</span></label>
        </v-col>
        <v-col
          cols="12"
          md="5"
        >
          <v-autocomplete
            v-model="OrderForm.os_code"
            :items="orderStatusMaster"
            outlined
            dense
            placeholder="注文状態"
            hide-details="auto"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-card-actions class="mt-2">
        <v-row>
          <v-col
            cols="12"
            md="1"
          >
            <label for="note">備考</label>
          </v-col>
          <v-col
            cols="12"
            md="11"
          >
            <v-textarea
              v-model="procNote"
              name="note"
              hide-details="auto"
              outlined
              :counter="100"
              dense
              rows="5"
              placeholder="備考"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-divider></v-divider>
      <v-card-actions align="center" class="d-flex justify-center">
        <v-btn
          color="primary"
          class="mr-5"
          :loading="submitStatus"
          @click="submit()"
        >
          <span>保存</span>
        </v-btn>
        <v-btn
          color="secondary"
          outlined
          @click="cancelClick"
        >
          <span>キャンセル</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {
  mapMutations,
  mapActions,
  mapState,
} from 'vuex'
import {
  required,
} from 'vuelidate/lib/validators'
import {
  mdiMinusCircle,
  mdiMinusBox,
  mdiPlusBox,
} from '@mdi/js'

import Addresses from '@/components/Addresses.vue'
import Product from '@/components/Product.vue'
import OrderEditCouponDialog from './OrderEditCouponDialog.vue'

export default {
  components: {
    Addresses,
    Product,
    OrderEditCouponDialog,
  },
  data: () => ({
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    newCusList: [],
    newCusId: '',
    newCusName: '',
    getSumPrice: 0,
    orderAmount: 0,
    orderTotal: 0,
    shippingFee: 0,
    couponDiscount: 0,
    prefcode: 0,
    procNote: '',
    addrIdList: [],
    icons: {
      mdiMinusCircle,
      mdiMinusBox,
      mdiPlusBox,
    },
    submitStatus: false,
    OrderForm: {
      // mbr_id: '',
      // ord_id: '',
      // order_no: '',
      // mbr_name: '',
      // order_dt: '',
      // order_total: '',
      // order_amount: '',
      // order_pud: '',
      // order_ead: '',
      // addr_name: '',
      // addr_zip: '',
      // addr_addr1: '',
      // addr_addr2: '',
      // addr_id: '',
      // os_code: '',
      // os_desc: '',
      // shipping_fee: '',
      // coupon_discount: 0,
      // details: [],
    },
    datePudMenu: false,
    dateEadMenu: false,
    ordDay: '',
    newHeaders: [
      {
        text: 'No.',
        value: 'cat_No',
        sortable: false,
        align: 'left',
        width: '6%',
        fixed: true,
      },
      {
        text: '商品コード',
        value: 'product_code',
        sortable: false,
        align: 'left',
        width: '13%',
        fixed: true,
      },
      {
        text: '商品名',
        value: 'product_name',
        sortable: false,
        align: 'left',
        width: '18%',
        fixed: true,
      },
      {
        text: '  ',
        value: 'sum_min',
        sortable: false,
        align: 'right',
        width: '2%',
        fixed: true,
      },
      {
        text: '数量',
        value: 'order_qty',
        sortable: false,
        align: 'right',
        width: '10%',
        fixed: true,
      },
      {
        text: '  ',
        value: 'sum_plus',
        sortable: false,
        align: 'right',
        width: '4%',
        fixed: true,
      },
      {
        text: '商品価格(円)',
        value: 'order_tip',
        sortable: false,
        align: 'right',
        width: '12%',
        fixed: true,
      },
      {
        text: '金額小計(円)',
        value: 'amount',
        sortable: false,
        align: 'right',
        width: '12%',
        fixed: true,
      },
      {
        text: '  ',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: '4%',
        fixed: true,
      },
    ],
    cartList: [
    ],
    adrName: '',
    orderStatuses: [],
    couponShow: false,
    totalPrice: 0,
    changeFlag: false,
  }),
  validations: {
    OrderForm: {
      mbr_id: {
        required,
      },
      mbr_name: {
        required,
      },
      addr_name: {
        required,
      },
    },

  },
  computed: {
    ...mapState('orderStore', ['selectedOrder', 'orderStatusMaster', 'shippingFeeInfo']),

    formatData() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return data.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },

    ordMbrIdErrors() {
      const errors = []
      if (!this.$v.OrderForm.mbr_id.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.OrderForm.mbr_id.required && errors.push('必須項目')

      return errors
    },
    ordMbrNameErrors() {
      const errors = []
      if (!this.$v.OrderForm.mbr_name.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.OrderForm.mbr_name.required && errors.push('必須項目')

      return errors
    },
    ordAdrNameErrors() {
      const errors = []
      if (!this.$v.OrderForm.addr_name.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.OrderForm.addr_name.required && errors.push('必須項目')

      return errors
    },

  },
  watch: {
    OrderForm: {
      handler() {
        this.changeFlag = true
      },
      deep: true,
    },
    cartList: {
      handler() {
        this.changeFlag = true
      },
      deep: true,
    },
    procNote: {
      handler() {
        this.changeFlag = true
      },
      deep: true,
    },

    orderAmount(value) {
      console.log('watch amount:', value, this.prefcode)
      if (value > 0 && this.prefcode > 0) {
        this.toGetShippingFee()
      }

      if (value === 0) {
        this.shippingFee = 0
      }
    },

    prefcode: {
      handler(value) {
        console.log('prefcode changed:', value)
        if (value > 0 && this.cartList.length > 0) {
          this.toGetShippingFee()
        }
        if (this.prefcode === 0) {
          this.shippingFee = 0
        }
      },
      deep: true,
    },
  },
  created() {
    this.loadData()
  },
  destroyed() {
    this.clearOrderStatusMaster()
    this.clearSelectedOrder()
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapMutations('orderStore', ['clearOrderStatusMaster', 'clearSelectedOrder']),
    ...mapActions('orderStore', ['loadOrder', 'editOrder', 'loadOrderStatus', 'getShippingFee']),

    // 注文総額を再計算する
    recalculateOrderTotal() {
      this.orderAmount = 0
      for (let i = 0; i < this.cartList.length; i += 1) {
        // console.log(this.cartList[i].amount)
        this.orderAmount += this.cartList[i].amount

        // this.OrderForm.order_total = this.getSumPrice
      }

      // +運賃
      this.orderTotal = this.orderAmount + this.shippingFee - this.couponDiscount

      console.log('orderTotal:', this.orderTotal)
    },

    // 運賃を取得する
    toGetShippingFee() {
      const addr = {
        prefcode: this.prefcode,
        details: this.cartList,
      }
      console.log('shipping addr:', addr)

      this.getShippingFee(addr).then(() => {
        console.log('shippingInfo:', this.shippingFeeInfo)
        this.shippingFee = this.shippingFeeInfo.shipping_fee
      })
    },

    getaddCusList(addCusList) {
      if (addCusList.length === 1) {
        this.newCusList = addCusList
        this.newCusId = this.newCusList[0].mbr_id
        this.newCusName = this.newCusList[0].mbr_sei + this.newCusList[0].mbr_mei
      } else {
        this.newCusId = ''
        this.newCusName = ''
      }
    },

    refreshAddress(addAdrList) {
      this.addrIdList = []
      if (addAdrList.length === 1) {
        this.OrderForm.addr_name = addAdrList[0].addr_sei + addAdrList[0].addr_mei
        this.OrderForm.addr_zip = addAdrList[0].addr_zip
        this.OrderForm.addr_addr1 = addAdrList[0].addr_addr1
        this.OrderForm.addr_addr2 = addAdrList[0].addr_addr2

        this.OrderForm.addr_id = addAdrList[0].addr_id

        // this.OrderForm.prefcode = addAdrList[0].prefcode

        this.addrIdList.push({
          addr_id: addAdrList[0].addr_id,
        })

        this.prefcode = addAdrList[0].prefcode
      } else {
        this.OrderForm.addr_name = ''
        this.OrderForm.addr_zip = ''
        this.OrderForm.addr_addr1 = ''
        this.OrderForm.addr_addr2 = ''
        this.OrderForm.addr_id = ''
      }
    },

    getaddProList(addProList) {
      console.log('addProlist:', addProList)
      this.cartList = []

      for (let i = 0; i < addProList.length; i += 1) {
        this.cartList.push({
          cat_No: i + 1,

          product_code: addProList[i].product_code,
          product_name: addProList[i].product_name,
          order_qty: addProList[i].order_qty ? addProList[i].order_qty : 1,
          order_tip: addProList[i].product_tip,

          amount: addProList[i].order_qty ? addProList[i].order_qty * addProList[i].product_tip : 1 * addProList[i].product_tip,
        })
      }
      console.log('cart:', this.cartList)
    },

    decrement(item) {
      const index = this.cartList.findIndex(el => el.cat_No === item.cat_No)
      if (this.cartList[index].order_qty > 1 && index >= 0) {
        this.cartList[index].order_qty -= 1
        this.cartList[index].amount = this.cartList[index].order_tip * this.cartList[index].order_qty
      }
      this.recalculateOrderTotal()
    },

    increment(item) {
      const index = this.cartList.findIndex(el => el.cat_No === item.cat_No)
      if (index >= 0) {
        this.cartList[index].order_qty += 1
      }
      this.cartList[index].amount = this.cartList[index].order_tip * this.cartList[index].order_qty
      this.recalculateOrderTotal()
    },

    remove(item) {
      const index = this.cartList.indexOf(item)

      if (index < 0) {
        console.log('remove failed.Item does not exist:', item)

        return
      }

      this.cartList.splice(index, 1)
      const orderContracts = []
      this.cartList.forEach((v, i) => {
        orderContracts[i] = v
        orderContracts[i].cat_No = i + 1
      })

      this.recalculateOrderTotal()
    },

    couponChanged(value) {
      this.couponDiscount = value
      this.recalculateOrderTotal()
    },

    cancelClick() {
      if (this.changeFlag) {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('他ページに遷移します。よろしいでしょうか？')) {
          this.jumpToListPage()
        }

        return
      }
      this.jumpToListPage()
    },

    loadData() {
      this.setOverlayStatus(true)
      Promise.all([
        this.loadOrderStatus(),
        this.loadOrder(this.$route.params.id),
      ]).then(() => {
        this.OrderForm = this.$deepCopy(this.selectedOrder)
        this.addrIdList = []
        this.addrIdList.push({
          addr_id: this.selectedOrder.addr_id,
        })
        this.orderAmount = this.selectedOrder.order_amount
        this.orderTotal = this.selectedOrder.order_total
        this.shippingFee = this.selectedOrder.shipping_fee
        this.couponDiscount = this.selectedOrder.coupon_discount
        this.prefcode = this.selectedOrder.prefcode

        this.cartList = this.selectedOrder.details

        for (let i = 0; i < this.cartList.length; i += 1) {
          this.cartList[i].cat_No = i + 1
          this.cartList[i].amount = this.cartList[i].order_qty * this.cartList[i].order_tip
        }
      }).catch(error => {
        if (error.response) {
          this.apierror.status = 'error'
          this.apierror.code = error.response.data.code
          this.apierror.messages = error.response.data.message
        }
      }).finally(() => {
        this.changeFlag = false
        this.setOverlayStatus(false)
      })
    },

    jumpToListPage() {
      this.$router.push({
        path: '/order-by-member-list',
        query: this.$route.params.query ?? {
        },
      })
    },

    submit() {
      if (this.$isObjectEqual(this.OrderForm, this.selectedOrder)) {
        this.jumpToListPage()

        return
      }
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitStatus = true

        this.OrderForm.order_amount = this.orderAmount
        this.OrderForm.shipping_fee = this.shippingFee
        this.OrderForm.coupon_discount = this.couponDiscount
        this.OrderForm.order_total = this.orderTotal
        this.OrderForm.details = this.cartList
        this.OrderForm.proc_memo = this.procNote
        this.OrderForm.prefcode = this.prefcode

        console.log('submitForm:', this.OrderForm)

        this.editOrder(this.OrderForm)
          .then(() => {
            this.jumpToListPage()
          })
          .catch(error => {
            if (error.response) {
              this.apierror.status = 'error'
              this.apierror.code = error.response.data.code
              this.apierror.messages = error.response.data.message
            }
          }).finally(() => {
            this.submitStatus = false
          })
      } else {
        console.log('error submit!!', this.OrderForm)
      }
    },
  },
}
</script>

<style scoped>
</style>
